body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.loading {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: linear-gradient(
    0deg,
    rgba(27,17,77,1) -8.35%,
    rgba(29,22,87,1) -5.1%,
    rgba(51,65,174,1) 28.53%,
    rgba(59,82,208,1) 43.72%,
    rgba(53,100,213,1) 51.31%,
    rgba(37,146,225,1) 67.59%,
    rgba(12,219,245,1) 89.28%,
    rgba(0,255,255,1) 100.13%
  );
  transform: rotate(-180deg);
  transition: opacity 3s;
  opacity: 1;
}

.loading--complete {
  opacity: 0;
  z-index: 0;
}

 
.loading svg {
  display: block;
  margin: auto; 
  width: 10rem;
  height: 10rem;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;  
  shape-rendering: auto;
}

.loading path {
  fill: none;
  stroke:  #ff0cb8;
  transform:scale(0.8);
  transform-origin:50px 50px;
}

#play-icon {
  position: fixed;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  width: 120px;
  height: 120px;
  fill: white;
  z-index: 1;
  opacity: 1;
  transition: opacity 1s;
  cursor: pointer;
}

#play-icon.fade-out {
  opacity: 0;
}